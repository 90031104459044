// Quasar Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the variables found in Quasar's.

// Check documentation for full list of Quasar variables

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: var(--q-primary)
$secondary: var(--q-secondary)
$tertiary: var(--q-tertiary)
$background: var(--q-background)
$white: #FFFFFF
$smoke: #F5F5F5
$silver: #C0C0C0
$lightgrey: #D3D3D3
$grey: #878585
$darkgrey: #606060
$ashes: #E8E8E8
$gainsboro: #E3E3E3
$sandybrown: #f7b25b
$whitesmoke: #F3F3F3
$shade: rgba(0, 0, 0, 0.05)

.bg-shade
  background-color: $shade

.card-inset
  box-shadow: inset 0 0 3px -2px rgba(0, 0, 0, 0.5)

$neutral: #FEFEFE
$positive: #35E599
$negative: #E4003F
$info: #6CC6E3
$warning: #F7B25B

// Global styles
$gainsboro-active: #DCDCDC
$table-header-color: #606573
$table-border-color: #FEFEFE
$table-color: #606573

// Darkmatter
$ctrl-darkmatter: #606573
$ctrl-darkmatter-lighten-1: #6F7584
$ctrl-darkmatter-lighten-2: #82899A
$ctrl-darkmatter-lighten-3: #929AAD
$ctrl-darkmatter-darken-1: #5B606E
$ctrl-darkmatter-darken-2: #535764
$ctrl-darkmatter-darken-3: #484B57

// Grey
$ctrl-grey: #E7E7E7
$ctrl-grey-lighten-1: #F1F1F1
$ctrl-grey-lighten-2: #FAFAFA
$ctrl-grey-lighten-3: #FEFEFE
$ctrl-grey-darken-1: #D7D7D7
$ctrl-grey-darken-2: #CDCDCD
$ctrl-grey-darken-3: #ACACAC

// Turquoise
$ctrl-turquoise: #6DD8C8
$ctrl-turquoise-lighten-1: #6F7584
$ctrl-turquoise-lighten-2: #82899A
$ctrl-turquoise-lighten-3: #929AAD
$ctrl-turquoise-darken-1: #5B606E
$ctrl-turquoise-darken-2: #535764
$ctrl-turquoise-darken-3: #484B57

// Red
$ctrl-red: #E76B66
$ctrl-red-lighten-1: #F3716B
$ctrl-red-lighten-2: #FB756F
$ctrl-red-lighten-3: #FE8681
$ctrl-red-darken-1: #DB6661
$ctrl-red-darken-2: #CF605C
$ctrl-red-darken-3: #C35B57
$ctrl-negative-dark-red: #c2000d
$ctrl-negative-light-red: #ffb6bf

// Yellow
$ctrl-yellow: #F0D250
$ctrl-yellow-lighten-1: #F7D953
$ctrl-yellow-lighten-2: #FCE16B
$ctrl-yellow-lighten-3: #FDE787
$ctrl-yellow-darken-1: #E4C84C
$ctrl-yellow-darken-2: #D9BE48
$ctrl-yellow-darken-3: #CDB444

// Orange
$ctrl-orange: #E98B5B
$ctrl-orange-lighten-1: #F3915F
$ctrl-orange-lighten-2: #FB9E70
$ctrl-orange-lighten-3: #FFAA81
$ctrl-orange-darken-1: #DE8557
$ctrl-orange-darken-2: #D37F53
$ctrl-orange-darken-3: #C8794F

// Blue
$ctrl-blue: #6CC6E3
$ctrl-blue-lighten-1: #71CFED
$ctrl-blue-lighten-2: #78DAF9
$ctrl-blue-lighten-3: #8BE2FD
$ctrl-blue-darken-1: #67BDD9
$ctrl-blue-darken-2: #61B3CD
$ctrl-blue-darken-3: #5CAAC3

// Purple
$ctrl-purple: #AA679D
$ctrl-purple-lighten-1: #B66EA8
$ctrl-purple-lighten-2: #C375B4
$ctrl-purple-lighten-3: #D78AC8
$ctrl-purple-darken-1: #9E6092
$ctrl-purple-darken-2: #925987
$ctrl-purple-darken-3: #87537D

// Other colors used in angular app
$ctrl-side-menu-item-hover: #383a43
$ctrl-sub-menu-item: #41444f
$ctrl-sub-menu-item-hover: #464954
$ctrl-table-striped-dark: #fbfbfb
$ctrl-table-head: #f3f3f3
$ctrl-row-select-bg: #e1f1f7
$ctrl-row-select-text: #668b96
$ctrl-neutral-button: rgba(0,0,0,0.1)

// Max width at which point
// current size ends
$breakpoint-xs: 599px
$breakpoint-sm: 1023px
$breakpoint-md: 1439px
$breakpoint-lg: 1919px

$breakpoint-xs-min: 0
$breakpoint-xs-max: 599px

$breakpoint-sm-min: 600px
$breakpoint-sm-max: 1023px

$breakpoint-md-min: 1024px
$breakpoint-md-max: 1439px

$breakpoint-lg-min: 1440px
$breakpoint-lg-max: 1919px

$breakpoint-xl-min: 1920px
$breakpoint-xl-max: 9999px
